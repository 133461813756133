export default [
  {
    path: '/repositories',
    name: 'repository-list',
    props: true,
    component: () => import('@/views/repositories/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des dépôts',
      breadcrumb: [
        {
          text: 'Dépôt',
        },
        {
          text: 'Liste des Dépôt',
          active: true,
        },
      ],
      roles: ['admin'],
    },
  },
  {
    path: '/repositories/create',
    name: 'repository-add',
    props: true,
    component: () => import('@/views/repositories/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter dépôts',
      breadcrumb: [
        {
          text: 'dépôts',
        },
        {
          text: 'Ajouter dépôt',
          active: true,
        },
      ],
      roles: ['admin'],
    },
  },
  {
    path: '/repositories/edit/:id',
    name: 'repository-edit',
    props: true,
    component: () => import('@/views/repositories/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier dépôt',
      breadcrumb: [
        {
          text: 'Dépôt',
        },
        {
          text: 'Modifier dépôt',
          active: true,
        },
      ],
      roles: ['admin'],
    },
  },
]
