export default [
  {
    path: '/payments',
    name: 'payment-list',
    props: true,
    component: () => import('@/views/payments/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des paiements',
      breadcrumb: [
        {
          text: 'Paiement',
        },
        {
          text: 'Liste des paiements',
          active: true,
        },
      ],
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/payments/create',
    name: 'payment-add',
    props: true,
    component: () => import('@/views/payments/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter paiement',
      breadcrumb: [
        {
          text: 'paiements',
        },
        {
          text: 'Ajouter paiement',
          active: true,
        },
      ],
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/payments/show/:id',
    name: 'payment-show',
    props: true,
    component: () => import('@/views/payments/Show.vue'),
    meta: {
      requireLogin: true,
      roles: ['admin', 'manager'],
    },
  },
  // {
  //   path: '/payments/edit/:id',
  //   name: 'payment-edit',
  //   props: true,
  //   component: () => import('@/views/payments/Edit.vue'),
  //   meta: {
  //     admin: true,
  //     requireLogin: true,
  //     pageTitle: 'Modifier Paiement',
  //     breadcrumb: [
  //       {
  //         text: 'Paiement',
  //       },
  //       {
  //         text: 'Modifier Paiement',
  //         active: true,
  //       },
  //     ],
  //     roles: ['admin', 'manager'],
  //   },
  // },
]
