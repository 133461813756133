export default [
  {
    path: '/delivery-notes',
    name: 'delivery-note-list',
    props: true,
    component: () => import('@/views/deliveryNotes/Index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Liste des Bon de livraison',
      breadcrumb: [
        {
          text: 'Bon de livraison',
        },
        {
          text: 'Liste des bons de livraison',
          active: true,
        },
      ],
      roles: ['admin', 'manager', 'supplier'],
    },
  },
  {
    path: '/delivery-notes/show/:id',
    name: 'delivery-note-show',
    props: true,
    component: () => import('@/views/deliveryNotes/Show.vue'),
    meta: {
      requireLogin: true,
      roles: ['admin', 'manager', 'supplier'],
    },
  },
  {
    path: '/delivery-notes/print-all',
    name: 'print-all',
    props: true,
    component: () => import('@/views/deliveryNotes/PrintAll.vue'),
    meta: {
      requireLogin: true,
      roles: ['admin', 'manager', 'supplier'],
    },
  },
]
