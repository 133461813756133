import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'
// import { getAPI } from '@/axios-api'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    role: '',
    token: '',
    isAuthenticated: false,
    isLoading: false,
    APIData: '',
    user: {
    },
    company: null,
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('token')) {
        state.token = localStorage.getItem('token')
        state.isAuthenticated = true
      } else {
        state.token = ''
        state.isAuthenticated = false
      }
    },
    updateStorage(state, { token }) {
      state.token = token
      state.isAuthenticated = true
      // state.refreshToken = refresh
    },
    destroyToken(state) {
      state.user = {}
      state.token = ''
      state.isAuthenticated = false
      // state.refreshToken = null
    },
    setIsLoading(state, status) {
      state.isLoading = status
    },
    setUser(state, user) {
      state.user = user
    },
    setCompany(state, company) {
      state.company = company
    },
    setToken(state, token) {
      state.token = token
      state.isAuthenticated = true
    },
    setRole(state, role) {
      state.role = role
    },

    removeToken(state) {
      state.role = ''
      state.token = ''
      state.isAuthenticated = false
    },
  },
  getters: {
    loggedIn(state) {
      return state.token != null
    },
  },
  actions: {
    userLogout(context) {
      if (context.getters.loggedIn) {
        context.commit('destroyToken')
        localStorage.clear()
      }
    },
    userLogin(context, usercredentials) {
      axios.defaults.headers.common.Authorization = ''
      return new Promise((resolve, reject) => {
        axios.post('api-token-auth/', {
          username: usercredentials.email,
          password: usercredentials.password,
        })
          .then(async response => {
            const { token } = response.data
            const { role } = response.data

            axios.defaults.headers.common.Authorization = `Token ${token}`
            context.commit('setToken', token)
            context.commit('setRole', role)
            localStorage.setItem('token', token)
            localStorage.setItem('email', JSON.stringify(response.data.email))
            localStorage.setItem('username', response.data.username)
            localStorage.setItem('role', response.data.role)
            localStorage.setItem('id', response.data.id)
            await axios.get('api/v1/users/me/')
              .then(res => {
                context.commit('setUser',
                  res.data)
              })
            await axios.get('api/companies/')
              .then(res => {
                context.commit('setCompany',
                  res.data[0])
              })
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  },
  strict: process.env.NODE_ENV !== 'production',
  modules: {

  },
  plugins: [createPersistedState()],
})
