export default [
  {
    path: '/vendors',
    name: 'vendor-list',
    props: true,
    component: () => import('@/views/vendors/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des vendeurs',
      breadcrumb: [
        {
          text: 'Vendeurs',
        },
        {
          text: 'Liste des Vendeurs',
          active: true,
        },
      ],
      roles: ['admin', 'accountant'],
    },
  },
  {
    path: '/vendors/create',
    name: 'vendor-add',
    props: true,
    component: () => import('@/views/vendors/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter Vendeur',
      breadcrumb: [
        {
          text: 'Vendeurs',
        },
        {
          text: 'Ajouter Vendeur',
          active: true,
        },
      ],
      roles: ['admin', 'accountant'],
    },
  },
  {
    path: '/vendors/edit/:id',
    name: 'vendor-edit',
    props: true,
    component: () => import('@/views/vendors/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier Vendeur',
      breadcrumb: [
        {
          text: 'Vendeurs',
        },
        {
          text: 'Modifier Vendeur',
          active: true,
        },
      ],
      roles: ['admin', 'accountant'],
    },
  },
  {
    path: '/vendors/show/:id',
    name: 'vendor-show',
    props: true,
    component: () => import('@/views/vendors/Show.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Détails Vendeur',
      breadcrumb: [
        {
          text: 'Vendeurs',
        },
        {
          text: 'Détails Vendeur',
          active: true,
        },
      ],
      roles: ['admin', 'accountant'],
    },
  },
]
