export default [
  {
    path: '/managers',
    name: 'manager-list',
    props: true,
    component: () => import('@/views/managers/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des Responsables depot',
      breadcrumb: [
        {
          text: 'Responsable depot',
        },
        {
          text: 'Liste des Responsable depot',
          active: true,
        },
      ],
      roles: ['admin'],
    },
  },
  {
    path: '/managers/create',
    name: 'manager-add',
    props: true,
    component: () => import('@/views/managers/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter responsable',
      breadcrumb: [
        {
          text: 'Responsables',
        },
        {
          text: 'Ajouter responsable',
          active: true,
        },
      ],
      roles: ['admin'],
    },
  },

  {
    path: '/managers/edit/:id',
    name: 'manager-edit',
    props: true,
    component: () => import('@/views/managers/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier responsables',
      breadcrumb: [
        {
          text: 'Responsables',
        },
        {
          text: 'Modifier responsable',
          active: true,
        },
      ],
      roles: ['admin'],
    },
  },
]
