export default [
  {
    path: '/delegations',
    name: 'delegation-list',
    props: true,
    component: () => import('@/views/delegations/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des Délégations',
      breadcrumb: [
        {
          text: 'Délégations',
        },
        {
          text: 'Liste des Délégations',
          active: true,
        },
      ],
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/delegations/create',
    name: 'delegation-add',
    props: true,
    component: () => import('@/views/delegations/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter delegation',
      breadcrumb: [
        {
          text: 'Delegations',
        },
        {
          text: 'Ajouter delegation',
          active: true,
        },
      ],
      roles: ['admin'],
    },
  },
  {
    path: '/delegations/edit/:id',
    name: 'delegation-edit',
    props: true,
    component: () => import('@/views/delegations/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier delegation',
      breadcrumb: [
        {
          text: 'Delegations',
        },
        {
          text: 'Modifier delegation',
          active: true,
        },
      ],
      roles: ['admin'],
    },
  },
]
