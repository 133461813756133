import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import {
  FormCheckboxPlugin, CardPlugin, FormSelectPlugin, SpinnerPlugin, IconsPlugin, AlertPlugin, OverlayPlugin, InputGroupPlugin, FormRadioPlugin, LayoutPlugin, BadgePlugin, ListGroupPlugin, TablePlugin,
} from 'bootstrap-vue'

Vue.use(FormCheckboxPlugin)
Vue.use(CardPlugin)
Vue.use(FormSelectPlugin)
Vue.use(SpinnerPlugin)
Vue.use(IconsPlugin)
Vue.use(AlertPlugin)
Vue.use(OverlayPlugin)
Vue.use(InputGroupPlugin)
Vue.use(FormRadioPlugin)
Vue.use(LayoutPlugin)
Vue.use(BadgePlugin)
Vue.use(ListGroupPlugin)
Vue.use(TablePlugin)
Vue.component(FeatherIcon.name, FeatherIcon)
