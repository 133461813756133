export default [
  {
    path: '/localities',
    name: 'localities-list',
    props: true,
    component: () => import('@/views/localities/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des localités',
      breadcrumb: [
        {
          text: 'localités',
        },
        {
          text: 'Liste des localité',
          active: true,
        },
      ],
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/localities/create',
    name: 'localities-create',
    props: true,
    component: () => import('@/views/localities/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter localité',
      breadcrumb: [
        {
          text: 'location',
        },
        {
          text: 'Ajouter localité',
          active: true,
        },
      ],
      roles: ['admin'],
    },
  },
  {
    path: '/localities/show/:id',
    name: 'localities-show',
    props: true,
    component: () => import('@/views/localities/Detail.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Show localité detail',
      breadcrumb: [
        {
          text: 'localité',
        },
        {
          text: 'localité',
          active: true,
        },
      ],
      roles: ['admin'],
    },
  },
  {
    path: '/localities/edit/:id',
    name: 'localities-edit',
    props: true,
    component: () => import('@/views/localities/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier localité',
      breadcrumb: [
        {
          text: 'localities',
        },
        {
          text: 'Modifier localité',
          active: true,
        },
      ],
      roles: ['admin'],
    },
  },
]
