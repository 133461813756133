export default [
  {
    path: '/bons-transferts',
    name: 'bon-transfert-list',
    props: true,
    component: () => import('@/views/transferVouchers/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des bon de transfert',
      breadcrumb: [
        {
          text: 'bons de transfert',
        },
        {
          text: 'Liste des bons de transferts',
          active: true,
        },
      ],
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/bons-transferts/reception-central',
    name: 'reception-transfert',
    props: true,
    component: () => import('@/views/transferVouchers/Reception.vue'),
    meta: {
      layout: 'vertical',
      requireLogin: true,
      pageTitle: 'Colis',
      breadcrumb: [
        {
          text: 'Réception central',
          active: true,
        },
      ],
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/demandes-transferts',
    name: 'demande-transfert',
    props: true,
    component: () => import('@/views/transferVouchers/Demande.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des demandes de transfert',
      breadcrumb: [
        {
          text: 'Demande de transfert',
        },
        {
          text: 'Liste des demandes de transferts',
          active: true,
        },
      ],
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/bons-transferts/create',
    name: 'bon-transfert-add',
    props: true,
    component: () => import('@/views/transferVouchers/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter bon de transfert',
      breadcrumb: [
        {
          text: 'Bon de tranfert',
        },
        {
          text: 'Ajouter Bon de transfert ',
          active: true,
        },
      ],
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/bons-transferts/validate/:id',
    name: 'bon-transfert-validate',
    props: true,
    component: () => import('@/views/transferVouchers/Validatee.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Valider bon de transfert',
      breadcrumb: [
        {
          text: 'Bon de tranfert',
        },
        {
          text: 'Valider Bon de transfert ',
          active: true,
        },
      ],
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/bons-transferts/show/:id',
    name: 'bon-transfert-show',
    props: true,
    component: () => import('@/views/transferVouchers/Show.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      roles: ['admin', 'manager'],
    },
  },
]
