export default [
  {
    path: '/roadmaps',
    name: 'roadmap-list',
    props: true,
    component: () => import('@/views/roadmaps/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des feuilles de route',
      breadcrumb: [
        {
          text: 'feuille de route',
        },
        {
          text: 'Liste des feuilles de route',
          active: true,
        },
      ],
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/roadmaps/create',
    name: 'roadmap-add',
    props: true,
    component: () => import('@/views/roadmaps/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter feuille de route',
      breadcrumb: [
        {
          text: 'feuilles de route',
        },
        {
          text: 'Ajouter feuille de route',
          active: true,
        },
      ],
      roles: ['admin', 'manager'],
    },
  },
  {
    admin: true,
    path: '/roadmaps/edit/:id',
    name: 'roadmap-edit',
    props: true,
    component: () => import('@/views/roadmaps/Edit.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Modifier feuille de route',
      breadcrumb: [
        {
          text: 'feuilles de route',
        },
        {
          text: 'Modifier feuille de route',
          active: true,
        },
      ],
      roles: ['admin'],
    },
  },
  {
    path: '/roadmaps/Show/:id',
    name: 'roadmap-show',
    props: true,
    component: () => import('@/views/roadmaps/Show.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      roles: ['admin', 'manager'],
    },
  },
]
