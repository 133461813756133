export default [
  {
    path: '/pickups',
    name: 'pickup-list',
    props: true,
    component: () => import('@/views/pickups/Index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Liste des pickups',
      breadcrumb: [
        {
          text: 'Pickups',
        },
        {
          text: 'Liste des pickups',
          active: true,
        },
      ],
      roles: ['admin', 'manager', 'supplier'],
    },
  },
  {
    path: '/pickups/create',
    name: 'pickup-add',
    props: true,
    component: () => import('@/views/pickups/Create.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Ajouter pickup',
      breadcrumb: [
        {
          text: 'pickups',
        },
        {
          text: 'Ajouter pickup',
          active: true,
        },
      ],
      roles: ['supplier'],
    },
  },
  {
    path: '/pickups/edit/:id',
    name: 'pickup-edit',
    props: true,
    component: () => import('@/views/pickups/Edit.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Modifier pickup',
      breadcrumb: [
        {
          text: 'Pickups',
        },
        {
          text: 'Modifier pickup',
          active: true,
        },
      ],
      roles: ['supplier'],
    },
  },
  {
    path: '/pickups/show/:id',
    name: 'pickup-show',
    props: true,
    component: () => import('@/views/pickups/Show.vue'),
    meta: {
      requireLogin: true,
      roles: ['admin', 'manager', 'supplier', 'sales'],
    },
  },
]
