export default [
  {
    path: '/delivery-mans',
    name: 'delivery-man-list',
    props: true,
    component: () => import('@/views/deliveryMans/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des livreurs',
      breadcrumb: [
        {
          text: 'Livreurs',
        },
        {
          text: 'Liste des livreurs',
          active: true,
        },
      ],
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/delivery-mans/create',
    name: 'delivery-man-add',
    props: true,
    component: () => import('@/views/deliveryMans/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter livreur',
      breadcrumb: [
        {
          text: 'Livreurs',
        },
        {
          text: 'Ajouter Livreur',
          active: true,
        },
      ],
      roles: ['admin'],
    },
  },

  {
    path: '/delivery-mans/edit/:id',
    name: 'delivery-man-edit',
    props: true,
    component: () => import('@/views/deliveryMans/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier livreur',
      breadcrumb: [
        {
          text: 'Livreurs',
        },
        {
          text: 'Modifier livreur',
          active: true,
        },
      ],
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/delivery-mans/show/:id',
    name: 'delivery-man-show',
    props: true,
    component: () => import('@/views/deliveryMans/Show.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Détails livreur',
      breadcrumb: [
        {
          text: 'Livreurs',
        },
        {
          text: 'Détails livreur',
          active: true,
        },
      ],
      roles: ['admin', 'manager'],
    },
  },
]
