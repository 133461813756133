export default [
  {
    path: '/cars',
    name: 'car-list',
    props: true,
    component: () => import('@/views/cars/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des voitures',
      breadcrumb: [
        {
          text: 'Voitures',
        },
        {
          text: 'Liste des Voitures',
          active: true,
        },
      ],
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/cars/create',
    name: 'car-add',
    props: true,
    component: () => import('@/views/cars/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter voiture',
      breadcrumb: [
        {
          text: 'Voitures',
        },
        {
          text: 'Ajouter Voiture',
          active: true,
        },
      ],
      roles: ['admin'],
    },
  },
  {
    path: '/cars/edit/:id',
    name: 'car-edit',
    props: true,
    component: () => import('@/views/cars/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier voiture',
      breadcrumb: [
        {
          text: 'Voitures',
        },
        {
          text: 'Modifier voiture',
          active: true,
        },
      ],
      roles: ['admin'],
    },
  },
  {
    path: '/cars/show/:id',
    name: 'car-show',
    props: true,
    component: () => import('@/views/cars/Show.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Détails voiture',
      breadcrumb: [
        {
          text: 'Voitures',
        },
        {
          text: 'Détails Voiture',
          active: true,
        },
      ],
      roles: ['admin'],
    },
  },
]
