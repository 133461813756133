export default [
  {
    path: '/expenses',
    name: 'expense-list',
    props: true,
    component: () => import('@/views/expenses/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des Dépenses',
      breadcrumb: [
        {
          text: 'Dépenses',
        },
        {
          text: 'Liste des Dépenses',
          active: true,
        },
      ],
      roles: ['admin', 'accountant'],
    },
  },
  {
    path: '/expenses/create',
    name: 'expense-add',
    props: true,
    component: () => import('@/views/expenses/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter Dépense',
      breadcrumb: [
        {
          text: 'Dépenses',
        },
        {
          text: 'Ajouter Dépense',
          active: true,
        },
      ],
      roles: ['admin', 'accountant'],
    },
  },
  {
    path: '/expenses/edit/:id',
    name: 'expense-edit',
    props: true,
    component: () => import('@/views/expenses/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier Dépense',
      breadcrumb: [
        {
          text: 'Dépenses',
        },
        {
          text: 'Modifier Dépense',
          active: true,
        },
      ],
      roles: ['admin', 'accountant'],
    },
  },
  {
    path: '/expenses/show/:id',
    name: 'expense-show',
    props: true,
    component: () => import('@/views/expenses/Show.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Détails Dépense',
      breadcrumb: [
        {
          text: 'Dépenses',
        },
        {
          text: 'Détails Dépense',
          active: true,
        },
      ],
      roles: ['admin', 'accountant'],
    },
  },
]
