export default [
  {
    path: '/dashboard/reporting/weekly-payments',
    name: 'reporting-weekly-payments',
    props: true,
    component: () => import('@/views/reporting/weeklyPayments.vue'),
    meta: {
      requireLogin: true,
      roles: ['admin'],
    },
  },
  {
    path: '/dashboard/reporting/states',
    name: 'reporting-states',
    props: true,
    component: () => import('@/views/reporting/stateReporting.vue'),
    meta: {
      requireLogin: true,
      roles: ['admin'],
    },
  },
  {
    path: '/dashboard/reporting/supplier-performance',
    name: 'reporting-supplier-performance',
    props: true,
    component: () => import('@/views/reporting/supplierPerformance.vue'),
    meta: {
      requireLogin: true,
      roles: ['admin'],
    },
  },
  {
    path: '/dashboard/reporting/top-drivers',
    name: 'reporting-top-drivers',
    props: true,
    component: () => import('@/views/reporting/topDrivers.vue'),
    meta: {
      requireLogin: true,
      roles: ['admin'],
    },
  },
  {
    path: '/dashboard/reporting/turnover',
    name: 'reporting-turnover',
    props: true,
    component: () => import('@/views/reporting/turnover.vue'),
    meta: {
      requireLogin: true,
      roles: ['admin'],
    },
  },
]
