export default [
  {
    path: '/governorates',
    name: 'governorate-list',
    props: true,
    component: () => import('@/views/governorates/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des Gouvernorats',
      breadcrumb: [
        {
          text: 'Gouvernorats',
        },
        {
          text: 'Liste des Gouvernorats',
          active: true,
        },
      ],
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/governorates/create',
    name: 'governorate-add',
    props: true,
    component: () => import('@/views/governorates/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter Gouvernorat',
      breadcrumb: [
        {
          text: 'Gouvernorats',
        },
        {
          text: 'Ajouter Gouvernorat',
          active: true,
        },
      ],
      roles: ['admin'],
    },
  },

  {
    path: '/governorates/edit/:id',
    name: 'governorate-edit',
    props: true,
    component: () => import('@/views/governorates/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier gouvernorat',
      breadcrumb: [
        {
          text: 'Gouvernorats',
        },
        {
          text: 'Modifier Gouvernorats',
          active: true,
        },
      ],
      roles: ['admin'],
    },
  },
]
