export default [
  {
    path: '/bons-retours',
    name: 'bon-retour-list',
    props: true,
    component: () => import('@/views/returnVouchers/Index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Liste des bon de retour',
      breadcrumb: [
        {
          text: 'bons de retours',
        },
        {
          text: 'Liste des bons de retours',
          active: true,
        },
      ],
      roles: ['admin', 'manager', 'supplier', 'sales'],
    },
  },
  {
    path: '/bons-retours/create',
    name: 'bon-retour-add',
    props: true,
    component: () => import('@/views/returnVouchers/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Retour',
      breadcrumb: [
        {
          text: 'Définitif',
        },
        {
          text: 'Nouveau bon de retour',
          active: true,
        },
      ],
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/bons-retours/show/:id',
    name: 'bon-retour-show',
    props: true,
    component: () => import('@/views/returnVouchers/Show.vue'),
    meta: {
      requireLogin: true,
      roles: ['admin', 'manager', 'supplier', 'sales'],
    },
  },
  {
    path: '/bons-retours/inter-agence',
    name: 'bon-retour-list-inter-agence',
    props: true,
    component: () => import('@/views/returnVouchers/interAgency/Index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Retour inter agence',
      breadcrumb: [
        {
          text: 'Retour inter agence',
        },
        {
          text: 'Liste',
          active: true,
        },
      ],
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/bons-retours/inter-agence/show/:id',
    name: 'bon-retour-inter-agence-show',
    props: true,
    component: () => import('@/views/returnVouchers/interAgency/Show.vue'),
    meta: {
      requireLogin: true,
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/bons-retours/inter-agence/create',
    name: 'bon-retour-inter-agence-add',
    props: true,
    component: () => import('@/views/returnVouchers/interAgency/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter retour inter-agencee',
      breadcrumb: [
        {
          text: 'Retour inter-agence',
        },
        {
          text: 'Ajouter retour inter-agence',
          active: true,
        },
      ],
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/bons-retours/inter-agence/validate/:id',
    name: 'bon-retour-inter-agence-validate',
    props: true,
    component: () => import('@/views/returnVouchers/interAgency/Validate.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Valider retour inter-agence',
      breadcrumb: [
        {
          text: 'retour inter-agence',
        },
        {
          text: 'Validation',
          active: true,
        },
      ],
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/demandes-inter-agence',
    name: 'demandes-inter-agence',
    props: true,
    component: () => import('@/views/transferVouchers/Demande.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des demandes de transfert',
      breadcrumb: [
        {
          text: 'Demande de transfert',
        },
        {
          text: 'Liste des demandes de transferts',
          active: true,
        },
      ],
      roles: ['admin', 'manager'],
    },
  },
]
