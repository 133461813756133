export default [
  {
    path: '/expense-categories',
    name: 'expense-categories-list',
    props: true,
    component: () => import('@/views/expenseCategories/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Catégorie de dépense',
      breadcrumb: [
        {
          text: 'Catégorie de dépense',
        },
        {
          text: 'Liste Catégorie de dépense',
          active: true,
        },
      ],
      roles: ['admin', 'accountant'],
    },
  },
  {
    path: '/expense-categories/create',
    name: 'expense-category-add',
    props: true,
    component: () => import('@/views/expenseCategories/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter Catégorie de Dépense',
      breadcrumb: [
        {
          text: 'Dépenses',
        },
        {
          text: 'Ajouter Catégorie de Dépense',
          active: true,
        },
      ],
      roles: ['admin', 'accountant'],
    },
  },
  {
    path: '/expense-categories/edit/:id',
    name: 'expense-category-edit',
    props: true,
    component: () => import('@/views/expenseCategories/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier Catégorie de Dépenses',
      breadcrumb: [
        {
          text: 'Dépenses',
        },
        {
          text: 'Modifier Catégorie de Dépense',
          active: true,
        },
      ],
      roles: ['admin', 'accountant'],
    },
  },
  {
    path: '/expense-categories/show/:id',
    name: 'expense-category-show',
    props: true,
    component: () => import('@/views/expenseCategories/Show.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Détails Catégorie de Dépense',
      breadcrumb: [
        {
          text: 'Dépenses',
        },
        {
          text: 'Détails Catégorie de Dépense',
          active: true,
        },
      ],
      roles: ['admin', 'accountant'],
    },
  },
]
