export default [
  {
    path: '/accountants',
    name: 'accountant-list',
    props: true,
    component: () => import('@/views/accountants/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des Comtables',
      breadcrumb: [
        {
          text: 'Comptables',
        },
        {
          text: 'Liste des comptables',
          active: true,
        },
      ],
      roles: ['admin'],
    },
  },
  {
    path: '/accountants/create',
    name: 'accountant-add',
    props: true,
    component: () => import('@/views/accountants/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter Comtable',
      breadcrumb: [
        {
          text: 'Comptables',
        },
        {
          text: 'Ajouter Comptable',
          active: true,
        },
      ],
      roles: ['admin'],
    },
  },

  {
    path: '/accountants/edit/:id',
    name: 'accountant-edit',
    props: true,
    component: () => import('@/views/accountants/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier Comptable',
      breadcrumb: [
        {
          text: 'Comptables',
        },
        {
          text: 'Modifier Comptable',
          active: true,
        },
      ],
      roles: ['admin'],
    },
  },
  {
    path: '/accountants/dashboard',
    name: 'dashboard-expense',
    props: true,
    component: () => import('@/views/dashboards/accountant.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
        },
        {
          text: 'Dashboard Comptable',
          active: true,
        },
      ],
      roles: ['accountant'],
    },
  },
]
