export default [
  {
    path: '/invoices',
    name: 'invoice-list',
    props: true,
    component: () => import('@/views/invoices/Index.vue'),
    meta: {

      requireLogin: true,
      pageTitle: 'Liste des Règlements',
      breadcrumb: [
        {
          text: 'Règlements',
        },
        {
          text: 'Liste des Règlements',
          active: true,
        },
      ],
      roles: ['admin', 'manager', 'supplier'],
    },
  },
  {
    path: '/invoices/create',
    name: 'invoice-add',
    props: true,
    component: () => import('@/views/invoices/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Générer Règlement',
      breadcrumb: [
        {
          text: 'Règlementss',
        },
        {
          text: 'Ajouter Règlement',
          active: true,
        },
      ],
      roles: ['admin'],
    },
  },
  {
    path: '/invoices/preview/:id',
    name: 'invoice-preview',
    props: true,
    component: () => import('@/views/invoices/Preview.vue'),
    meta: {
      requireLogin: true,
      roles: ['admin'],
    },
  },
  {
    path: '/invoices/show/:id',
    name: 'invoice-show',
    props: true,
    component: () => import('@/views/invoices/Show.vue'),
    meta: {
      requireLogin: true,
      roles: ['admin', 'manager', 'supplier'],
    },
  },
]
