export default [
  {
    path: '/suppliers',
    name: 'supplier-list',
    props: true,
    component: () => import('@/views/suppliers/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Liste des Fournisseurs',
      breadcrumb: [
        {
          text: 'Fournisseurs',
        },
        {
          text: 'Liste des fournisseurs',
          active: true,
        },
      ],
      roles: ['admin', 'manager', 'sales'],
    },
  },
  {

    path: '/suppliers/create',
    name: 'supplier-add',
    props: true,
    component: () => import('@/views/suppliers/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Ajouter Fournisseur',
      breadcrumb: [
        {
          text: 'Fournisseurs',
        },
        {
          text: 'Ajouter fournisseur',
          active: true,
        },
      ],
      roles: ['admin', 'manager', 'sales'],
    },
  },

  {
    path: '/suppliers/edit/:id',
    name: 'supplier-edit',
    props: true,
    component: () => import('@/views/suppliers/Edit.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Modifier fournisseur',
      breadcrumb: [
        {
          text: 'Fournisseurs',
        },
        {
          text: 'Modifier fournisseur',
          active: true,
        },
      ],
      roles: ['admin', 'manager', 'sales'],
    },
  },
  {
    path: '/suppliers/show/:id',
    name: 'supplier-show',
    props: true,
    component: () => import('@/views/suppliers/Show.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: 'Détails fournisseur',
      breadcrumb: [
        {
          text: 'Fournisseurs',
        },
        {
          text: 'Détails fournisseur',
          active: true,
        },
      ],
      roles: ['admin', 'manager', 'sales'],
    },
  },
]
