/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import Vue from 'vue'
import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_baseUrl
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

const config = {
  baseURL: process.env.VUE_APP_baseUrl,
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
  config => config,
  error => Promise.reject(error),
)

_axios.interceptors.response.use(
  response => response,
  error => Promise.reject(error),
)

const Plugin = {
  install(Vue) {
    Vue.axios = _axios
    window.axios = _axios
    Object.defineProperties(Vue.prototype, {
      axios: {
        get() {
          return _axios
        },
      },
      $axios: {
        get() {
          return _axios
        },
      },
    })
  },
}

Vue.use(Plugin)

export default Plugin
