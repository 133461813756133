export default [
  {
    path: '/weights',
    name: 'weight-list',
    props: true,
    component: () => import('@/views/weights/Index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Liste des poids de colis',
      breadcrumb: [
        {
          text: 'Poids de colis',
        },
        {
          text: 'Liste des Poids',
          active: true,
        },
      ],
      roles: ['admin'],
    },
  },
  {
    path: '/weights/create',
    name: 'weight-add',
    props: true,
    component: () => import('@/views/weights/Create.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Ajouter poids',
      breadcrumb: [
        {
          text: 'Poids de colis',
        },
        {
          text: 'Ajouter poids',
          active: true,
        },
      ],
      roles: ['admin'],
    },
  },
  {
    path: '/weights/edit/:id',
    name: 'weight-edit',
    props: true,
    component: () => import('@/views/weights/Edit.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Modifier poids',
      breadcrumb: [
        {
          text: 'Poids de colis',
        },
        {
          text: 'Modifier poids',
          active: true,
        },
      ],
      roles: ['admin'],
    },
  },
]
