export default [
  {
    path: '/bons-echanges',
    name: 'bon-echange-list',
    props: true,
    component: () => import('@/views/exchangeVouchers/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: "Liste des bons d'échanges",
      breadcrumb: [
        {
          text: "Bon d'échange",
        },
        {
          text: "Liste des bons d'échanges",
          active: true,
        },
      ],
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/bons-echanges/create',
    name: 'bon-echange-add',
    props: true,
    component: () => import('@/views/exchangeVouchers/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: "Ajouter bon d'échange",
      breadcrumb: [
        {
          text: "Bon d'échange",
        },
        {
          text: "Ajouter bon d'échange",
          active: true,
        },
      ],
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/bons-echanges/show/:id',
    name: 'bon-echange-show',
    props: true,
    component: () => import('@/views/exchangeVouchers/Show.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      roles: ['admin', 'manager'],
    },
  },
]
